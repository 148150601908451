(() => {
	const FIREHOSE_PERCENTAGE = parseInt(
		document.getElementById('firehose-percentage').dataset.firehosePercentage,
		10
	);

	window.DDC = window.DDC || {};
	window.DDC.dataLayer = window.DDC.dataLayer || {};
	window.DDC.dataLayer.page = window.DDC.dataLayer.page || {};

	const wueActionMap = {
		'auto updated': 'auto update',
		added: 'add',
		applied: 'apply',
		changed: 'change',
		clicked: 'click',
		collapsed: 'collapse',
		completed: 'complete',
		displayed: 'display',
		expanded: 'expand',
		impression: 'view',
		navigated: 'navigate',
		opened: 'open',
		received: 'receive',
		removed: 'remove',
		saved: 'save',
		selected: 'select',
		submitted: 'submit',
		swiped: 'swipe',
		updated: 'update',
		viewed: 'view'
	};

	const replaceValues = (inputString, map) => {
		let str;
		if (typeof inputString === 'string') {
			str = inputString
				.toLowerCase()
				.split(' ')
				.map((word) => map[word] || word)
				.join(' ');
		}
		return str;
	};

	const filterCdlProperties = (payload) => {
		let propsToFilter = [];
		if (payload.event.gtmEvent === 'specialsEvent') {
			propsToFilter = [
				'specialPersonalized',
				'title',
				'specialId',
				'specialAutoGen',
				'promoIndex',
				'customAsset',
				'gtm.uniqueEventId',
				'promoIndex',
				'oemId',
				'specialCategory'
			];
		}

		if (payload.event.gtmEvent === 'widgetUserEvent') {
			propsToFilter = [
				'formId',
				'fieldValue',
				'formEventId',
				'sourceData',
				'value',
				'widgetState',
				'gtm.uniqueEventId',
				'uuid'
			];
		}

		if (payload.event.gtmEvent === 'thirdPartyUserEvent') {
			propsToFilter = [
				'gtm.uniqueEventId',
				'intention',
				'vehicleUuid',
				'product',
				'step'
			];
		}

		propsToFilter.forEach((prop) => {
			// eslint-disable-next-line no-param-reassign
			delete payload.event[prop];
		});

		if (payload.event.windowId) {
			// eslint-disable-next-line no-param-reassign
			payload.event.widgetId = payload.event.windowId;
			// eslint-disable-next-line no-param-reassign
			delete payload.event.windowId;
		}
	};

	const replaceUrl = (inputString) => {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		const match = inputString.match(urlPattern);
		let destinationUrl = '';

		if (match) {
			[destinationUrl] = match;
			const updatedString = inputString.replace(urlPattern, 'destination url');
			return { updatedString, destinationUrl };
		}

		return { updatedString: inputString, destinationUrl: '' };
	};

	const validateNonZeroLengthString = (str, name) => {
		if (typeof str !== 'string' || str.length === 0) {
			throw new Error(`${name} must be a non-zero length string. Got: ${str}.`);
		}
	};

	// Unique eventId generation for each page
	const generateIdCrypto = () => {
		const randomBuffer = new window.Uint32Array(24);
		window.crypto.getRandomValues(randomBuffer);
		let id = '';
		for (let i = 0; i < randomBuffer.length; i++) {
			const random = Math.floor((randomBuffer[i] / (0xffffffff + 1)) * 62);
			if (random > 35) {
				id += (random - 26).toString(36).toUpperCase();
			} else {
				id += random.toString(36);
			}
		}
		return id;
	};

	const generateId = () => {
		if (window.crypto && window.crypto.getRandomValues) {
			return generateIdCrypto();
		} else {
			return Math.trunc(Math.random() * 1000000000);
		}
	};

	const shouldTrack = () => {
		const host = window.location.hostname;
		const isDev = host.includes('.web-np.') || host.includes('.localhost.');
		return isDev || Math.floor(Math.random() * (100 - 0) + 1) <= 10;
	};

	window.DDC.dataLayer.page.generateId = generateId;

	// Set the pageViewId
	window.DDC.dataLayer.page.pageViewId = generateId();

	if (!window.setNRAttr) {
		window.setNRAttr = (k, v) => {
			if (window?.newrelic && k && v) {
				window.newrelic.setCustomAttribute(k, v);
			}
		};
	}
	if (!window.sendNRAction) {
		window.sendNRAction = (k, o) => {
			const obj = o;
			if (window?.newrelic && k && obj) {
				obj.eventType = 'PageAction';
				window.newrelic.addPageAction(k, obj);
			}
		};
	}

	window.DDC.pushToFirehose = (
		eventCategory,
		eventName,
		payload,
		percentageToSend = 1
	) => {
		if (Math.ceil(Math.random() * 100) <= percentageToSend) {
			const { element: _element, ...eventData } = payload || {};

			const apiUri = '/api/widget/ws-tagging/firehose';
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					eventCategory,
					eventName,
					payload: {
						...eventData,
						...(Object.keys(eventData).length && {
							eventData
						})
					}
				})
			};
			fetch(apiUri, options)
				.then((response) => response.json())
				.then((data) => {
					window?.DDC?.log?.('log', data);
				})
				.catch((error) => {
					window?.DDC?.log?.('warn', error);
				});
		}
	};

	window.DDC.pushToPixall = (payloadIn, { event, source } = {}) => {
		const payload = JSON.parse(JSON.stringify(payloadIn));
		const { pageViewId } = window.DDC.dataLayer.page;

		payload.experimental = payload.experimental || {};
		if (
			!payload.experimental.eventId ||
			(event !== 'cms-web-pixall-pageview' &&
				payload.experimental.eventId === pageViewId)
		) {
			payload.experimental.eventId = generateId();
		}
		payload.experimental.pageViewId = pageViewId;

		window._pxam.push(payload);

		const { category = '', eventSource = '', eventType = '' } = payload;

		const eventName =
			event || [eventSource, eventType, category].filter(Boolean).join('|');

		window.DDC.pushToFirehose(
			'pixall',
			payload.eventType || eventName,
			payload,
			FIREHOSE_PERCENTAGE || 1
		);

		if (shouldTrack()) {
			const additionalProperties = [
				'category',
				'contentType',
				'description',
				'eventSource',
				'eventType',
				'label'
			].reduce((acc, key) => {
				if (Object.prototype.hasOwnProperty.call(payload, key)) {
					const newKey = `pixall${key.charAt(0).toUpperCase()}${key.slice(1)}`;
					acc[newKey] = payload[key];
				}
				return acc;
			}, {});

			window.sendNRAction('pixall_send_data', {
				...(source && { mappingSource: source }),
				eventId: payload.experimental.eventId,
				pageViewId: payload.experimental.pageViewId,
				eventName,
				...additionalProperties
			});
		}

		if (window?.sendToEventViewer) {
			window?.sendToEventViewer?.(event, payload, source);
		}
	};

	/**
	 * Method for pushing events into the Google Tag Manager DataLayer
	 * @param event {Object} The event object to check against
	 * @param dataToExclude {Object} This is data that was excluded from the widgetUserEvent
	 */
	window.DDC.pushToGtmDataLayer = (event, dataToExclude) => {
		let pushedToGtmDataLayer = false;
		const eventOut = event;
		if (window?.dataLayer?.push) {
			const { pageViewId } = window.DDC.dataLayer.page;
			if (eventOut?.event?.includes('ddc.')) {
				eventOut.eventData = eventOut.eventData || {};
				eventOut.eventData.eventId =
					eventOut.event === 'ddc.page.view' ? pageViewId : generateId();
				eventOut.eventData.pageViewId = pageViewId;
				// adding event, eventName, eventType as OSIRIS widgets may not add this
				const attributes = ['event', 'eventName', 'eventType'];
				attributes.forEach((attr) => {
					eventOut.eventData[attr] = eventOut.eventData[attr] || eventOut.event;
				});
				// added to be consistent with DDC.dataLayer.vehicles[N].modelyear
				if (event.eventData.year) {
					eventOut.eventData.modelYear = event.eventData.year;
				}
			} else {
				eventOut.eventId =
					eventOut.event === 'ddc.page.view' ? pageViewId : generateId();
			}
			window.dataLayer.push(eventOut);
			pushedToGtmDataLayer = true;
		} else {
			window?.DDC?.log('dataLayer or dataLayer.push is undefined', eventOut);
		}

		/*
		 * 	Send to coxAutoDataLayer if in the allow list of widgets.
		 */
		if (
			new Set(['ws-car-customizer', 'ws-inv-facets']).has(event.widgetName) ||
			(window.DDC.Flags['cms-web-all-wue-to-cdl'] &&
				event.event === 'widgetUserEvent') ||
			(window.DDC.Flags['cms-web-all-specialsEvent-to-cdl'] &&
				event.event === 'specialsEvent') ||
			(window.DDC.Flags['cms-web-all-thirdPartyUserEvent-to-cdl'] &&
				event.event === 'thirdPartyUserEvent')
		) {
			const {
				action,
				result,
				event: gtmEvent,
				elementCTA,
				// specialsEvent data
				title,
				specialType,
				specialPlacementType,
				// thirdPartyUserEvent data
				step,
				// misc
				...remainingCdlData
			} = event;

			let intention = result;
			let destinationUrl;

			if (typeof intention === 'string') {
				intention = replaceValues(result, wueActionMap) || result;
				const replacementObj = replaceUrl(intention);

				intention = replacementObj.updatedString;
				destinationUrl = replacementObj.destinationUrl;
			}

			const payload = {
				event: {
					...(intention && { intention }),
					elementText: elementCTA,
					gtmEvent,
					...remainingCdlData,
					...(typeof remainingCdlData?.element !== 'string' && { element: '' }),
					...(event.event === 'specialsEvent' && { element: title || '' }),
					...(event.event === 'thirdPartyUserEvent' && {
						element: `${event.element} ${step}` || ''
					}),
					...dataToExclude,
					action:
						replaceValues(action || specialType, wueActionMap) ||
						action ||
						specialType,
					...(destinationUrl && { destinationUrl }),
					...(specialPlacementType && { location: specialPlacementType })
				}
			};

			filterCdlProperties(payload);
			window.coxAutoDataLayer?.push?.(payload);
		}

		return pushedToGtmDataLayer;
	};

	/**
	 * Method used by OSIRIS widgets to push widgetUserEvents to Google Analytics and Google Tag Manager DataLayer
	 * @param {Object} data - The event object to pass along to trackEvent
	 * @param {Object} data.dataToExcludeFromWidgetUserEvent - Data to exclude from the widgetUserEvent
	 */
	window.DDC.fireTrackingEvent = (data) => {
		/* Remove dataToExcludeFromWidgetUserEvent so it does not go into widgetUserEvent. It is only needed for tracking widgets.
			This is how we pass additional data from OSIRIS widgets to our tracking widgets.
			See how this is used in v9/viewmodel/widgets/tracking/framework/runtime/InteractionListener.js
			*/
		const {
			dataToExcludeFromWidgetUserEvent,
			...dataToIncludeInWidgetUserEvent
		} = data;
		jQuery(document).trigger('trackEvent', [
			dataToIncludeInWidgetUserEvent,
			dataToExcludeFromWidgetUserEvent
		]);
	};

	/**
	 * Method used by ws-tagging to receive trackEvents for tracking purposes.
	 *
	 * @param {Function} callback - The callback function to handle the trackEvent
	 * @param {Object} callback.event - The event object
	 * @param {Object} callback.eventData - The event data object
	 * @param {Object} [callback.optionalData] - Optional additional data
	 */
	window.DDC.receiveTrackingEvent = (callback) => {
		jQuery(document).on('trackEvent', (event, eventData, optionalData) => {
			callback(event, eventData, optionalData);
		});
	};

	/**
	 * Method used by tracking code that wants the additional properties that ws-tracking appends to events
	 * before they go into window.dataLayer
	 *
	 * @param {String} event - A string for the event name
	 * @param {Object} eventData - An object of properties for the eventData
	 * @param {Element} eventData.element - A DOM Element associated with the event
	 * @throws {Error} If event is not a non-zero length string or eventData.element is not a DOM Element
	 * @description This method is used to add things to window.dataLayer through the DDC.pushEvent method on Dealer.com sites.
	 * This method will make sure ws-tracking manipulates the event as necessary to add missing properties.
	 */
	window.DDC.pushEvent = ({ event, eventData }) => {
		validateNonZeroLengthString(event, 'event name');
		if (!(eventData?.element instanceof Element)) {
			throw new Error('eventData.element must be a DOM Element.');
		}

		if (window?.DDC?.WidgetData?.['ws-tracking']?.pushEvent) {
			window.DDC.WidgetData['ws-tracking'].pushEvent({
				event,
				eventData
			});
		} else {
			window.addEventListener('pushEventReady', () => {
				window.DDC.WidgetData['ws-tracking'].pushEvent({
					event,
					eventData
				});
			});
		}
	};
})();
